import { useEffect, useState } from "react";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCard from "../productCard/productCard";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { config } from "../../config";
import { useTranslation } from "react-i18next";

const CategoryProducts = ({ category }) => {
  const [products, setProducts] = useState([]);
  const { i18n } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    axios
      .get(config.baseURL + `Products/getProductsForHomePage?id=${category.id}`)
      .then((response) => {
        setProducts(response.data);
      });
  }, []);

  return (
    <div>
      <h1>{i18n.language === "en" ? category.name_en : category.name}</h1>
      <div className="productsCard_container">
        {products.length ? (
          products.map((product) => (
            <ProductCard
              name={product.name}
              price={product.price}
              image={product.image}
              image_type={product.image_type}
              id={product.internal_code}
            />
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryProducts;
