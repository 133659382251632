import React from "react";
import "./reviewCard.css";
import { Rating } from "@mui/material";

const ReviewCard = ({ name, reveiw }) => {
  return (
    <div className="review-card">
      <div className="rating">
        <Rating name="half-rating" defaultValue={5} precision={5} />
      </div>

      <p className="customer-review">{reveiw}</p>

      <div className="customer-name">{name}</div>
    </div>
  );
};

export default ReviewCard;
