import React, { useEffect, useState } from "react";
import "./categories.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

const Categories = ({categoriesProp}) => {
  const [categories, setCategories] = useState(categoriesProp);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 3,
    },
  };

  useEffect(() => {
    setCategories(categoriesProp);
  }, [categoriesProp]);


  return (
    <div className="categories_section">
      <h2 className="categories_header">{t("categories")}</h2>
      <div className="categories_container">
        {categories.length ? (
          <Carousel
            responsive={responsive}
            autoPlay
            infinite
            autoPlaySpeed={1800}
          >
            {categories.length ? (
              categories.map((category) => (
                <Link
                  to={`/category/${category.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="category">
                    <img
                      className="category_img"
                      src={category.image}
                      alt="FFF"
                    />
                    <div className="category_name">
                      {i18n.language === "en"
                        ? category.name_en
                        : category.name}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
          </Carousel>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
