import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../config";
import NavBar from "../../components/nav-bar/navBar";
import ProductCard from "../../components/productCard/productCard";
import { CircularProgress } from "@mui/material";

const SearchMobilePage = () => {
  let { productName } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts([]);
    axios
      .get(config.baseURL + "Products/getProductForMobileSearch", {
        params: {
          productName: productName,
        },
      })
      .then((res) => {
        console.log(res)
        setProducts(res.data);
      });
  }, [productName]);

  return (
    <div>
      <NavBar />

      <div className="search-page_mobile">
        {products.length ? (
          <div className="search_productsCard_container_mobile">
            {products.map((product) => (
              <ProductCard
                name={product.name}
                price={product.price}
                image={product.image}
                image_type={product.image_type}
                id={product.internal_code}
              />
            ))}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchMobilePage;
