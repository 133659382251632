import React, { useEffect, useState } from "react";
import NavBar from "../../components/nav-bar/navBar";
import Header from "../../components/header/header";
import Offers from "../../components/offers/offers";
import Categories from "../../components/categories/categories";
import Products from "../../components/products/products";
import Advantages from "../../components/advantages/advantages";
import NewProducts from "../../components/newProducts/newProducts";
import Footer from "../../components/footer/footer";
import Reviews from "../../components/reviews/reviews";
import axios from "axios";
import { config } from "../../config";
import CategoryProducts from "../../components/categoryProducts/categoryProducts";

const HomePage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories([]);

    axios.get(config.baseURL + "Categories/getCategories").then((response) => {
      const categoriesWithImages = response.data.map((category) => ({
        ...category,
        image: `data:${category.image_type};base64,${category.image}`,
      }));

      setCategories(categoriesWithImages);
    });
    // Update categories with base64-encoded image data
  }, []);

  return (
    <div>
      <NavBar />
      <div className="body">
        <Categories categoriesProp={categories} />
        <Header />
        {/* <Offers /> */}
        {/* <Products /> */}
        {categories.map((category) => (
          <div key={category.id} className="category-section">
            <CategoryProducts category={category} />
          </div>
        ))}
        <NewProducts />
        <Reviews />
        <Advantages />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
