import React, { useEffect, useState } from "react";
import "./search.css";
import NavBar from "../../components/nav-bar/navBar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import { CircularProgress } from "@mui/material";
import ProductCard from "../../components/productCard/productCard";

const SearchPage = () => {
  let { category, productName } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts([]);
    axios
      .get(config.baseURL + "Products/getProductByCategoryName", {
        params: {
          productName: productName,
          Category: category,
        },
      })
      .then((res) => {
        setProducts(res.data);
      });
  }, [category, productName]);
  return (
    <div>
      <NavBar />

      <div className="search-page">
        {products.length ? (
          <div className="search_productsCard_container">
            {products.map((product) => (
              <ProductCard
                name={product.name}
                price={product.price}
                image={product.image}
                image_type={product.image_type}
                id={product.internal_code}
              />
            ))}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
