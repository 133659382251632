export const reviews = [
  {
    review: "احنا طلبنا الاوردر من تانى يوم ووصل النهاردة وحلو جدا ماتريال وتقفيل وخدمة توصيل كل حاجة ماشاء الله اللهم بارك تمام",
    clientName: "نوره الشريف",
  },

  {
    review: "أنا استلمت الكنبة وتسلم ايديكم جميلة ماشاء الله كنت عاوز اعمل ركنة كمان ان شاء الله",
    clientName: "شريف عيسى",
  },

  {
    review: "الكنبة وصلت الحمدلله وجميلة جدا اللهم بارك وفعلا على الطبيعة نفس الصورة ... شكرا جدا وان شاء الله مش اخر تعامل",
    clientName: "سمية",
  },

  {
    review: "شكرا على الركنة شغل محترم .. ان شاء الله مش هيكون اخر تعامل",
    clientName: "محمد عبدالمنعم",
  },

];
