import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../components/nav-bar/navBar";
import Footer from "../../components/footer/footer";
import "./productDetails.css";
import PrimaryButton from "../../components/button/primaryButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { connect } from "react-redux";
import KetanColor from "../../assets/KetanColor.png";
import KatefaColor from "../../assets/KatefaColor.png";
import { UpdateImageContext } from "../../context/updateImage";
import Carousel from "react-multi-carousel";
import ImageZoom from "../../components/imageZoom/imageZoom";
import SecondaryButton from "../../components/button/secondaryButton";

const ProductDetails = ({ addToCart, removeFromCart, isInCart }) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(false);
  const [blurClass, setBlurClass] = useState("");
  const [cornerDirection, setCornerDirection] = useState("");
  const [withDirection, setWithDirection] = useState("");
  const [oppositeDirection, setOppositeDirection] = useState("");
  const [matrialType, setMatrialType] = useState("");
  const [colorNumber, setColorNumber] = useState();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  const handleClick = (photo) => {
    setSelectedPhoto(true);
    setBlurClass("blur-background");
  };

  const handleClose = () => {
    setSelectedPhoto(false);
    document.body.classList.remove("blur-background");
    setBlurClass("");
  };

  const handleSelectDirection = (direction) => {
    if (direction === "with") {
      setCornerDirection("مع اتجاه الصورة");
      setOppositeDirection("");
      setWithDirection("selected");
    } else {
      setCornerDirection("عكس اتجاه الصورة");
      setOppositeDirection("selected");
      setWithDirection("");
    }
  };

  async function handleDirectBuy() {
    await addToCart({
      id,
      name: productName,
      price: productPrice,
      imageUrl: "",
      imageType: "",
      cornerDirection: cornerDirection,
      matrialType: matrialType,
      color: colorNumber,
      productLink: `https://warshty-store.egyptystore.com/#/productDetails/${id}`,
    });
    await setUpdateImage(!updateImage);

    navigate("/checkout");
  }

  useEffect(() => {
    axios
      .get(config.baseURL + `Products/getProduct?id=${id}`)
      .then((response) => {
        setProduct(response.data);
        setProductName(response.data.name);
        setProductPrice(response.data.price);
      });

    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div>
      {selectedPhoto ? (
        <div className="modal">
          <img
            src={`data:${product.image_type};base64,${product.image}`}
            alt="FFF"
            className="modal-photo"
          />
          <button onClick={handleClose} className="close-button">
            Close
          </button>
        </div>
      ) : null}
      <div className={`productDetails-page ${blurClass}`}>
        <NavBar />
        <div className="body">
          <div className="productDetials-content">
            {product ? (
              <div className="product-content left-column">
                <h3 className="productName">{product.name}</h3>
                <div className="productPrice">
                  {String(product.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  EGP
                </div>

                {product.category_name === "ركن" ||
                product.category_name === "ركنة" ? (
                  <div className="product_direction">
                    <div dir="rtl" className="direction-text">
                      اتجاه الركنة :{" "}
                    </div>
                    <div className="directions_container">
                      <div
                        className={`direction ${withDirection}`}
                        onClick={() => handleSelectDirection("with")}
                      >
                        اتجاه الصورة
                      </div>
                      <div
                        className={`direction ${oppositeDirection}`}
                        onClick={() => handleSelectDirection("opposite")}
                      >
                        عكس اتجاه الصورة
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="matrial-type">
                  <div dir="rtl" className="matrial-text">
                    نوع القماش :
                  </div>
                  <select
                    value={matrialType}
                    className="checkOut-input"
                    onChange={(e) => setMatrialType(e.target.value)}
                  >
                    <option value="...">...</option>
                    <option value="كتان">كتان</option>
                    <option value="قطيفة">قطيفة</option>
                  </select>
                </div>

                {/* <div className="product-color">
                  <div dir="rtl" className="color-text">
                    اللون (قماش قطيفة) :
                  </div>

                  <div className="color-container"></div>
                </div> */}

                <div className="matrial-type">
                  <div dir="rtl" className="matrial-text">
                    رقم اللون
                  </div>
                  <input
                    className="checkOut-input"
                    value={colorNumber}
                    onChange={(e) => setColorNumber(e.target.value)}
                    type="number"
                  />
                </div>

                <div className="add-cart">
                  <div className="increase-decrease-container">
                    <button
                      className="increase-decrease-button"
                      style={{ cursor: "pointer" }}
                    >
                      -
                    </button>
                    <button className="increase-decrease-button">1</button>
                    <button
                      className="increase-decrease-button"
                      style={{ cursor: "pointer" }}
                    >
                      +
                    </button>
                  </div>
                  {isInCart ? (
                    <div className="button_container">
                      <PrimaryButton
                        text="حذف من السلة"
                        handleClick={() => removeFromCart(id)}
                      />
                    </div>
                  ) : (
                    <PrimaryButton
                      text="اضافة للسلة"
                      handleClick={() => {
                        addToCart({
                          id,
                          name: productName,
                          price: productPrice,
                          imageUrl: "",
                          imageType: "",
                          cornerDirection: cornerDirection,
                          matrialType: matrialType,
                          color: colorNumber,
                          productLink: `https://warshty-store.egyptystore.com/#/productDetails/${id}`,
                        });
                        setUpdateImage(!updateImage);
                      }}
                    />
                  )}
                  <SecondaryButton
                    handleClick={handleDirectBuy}
                    text="شراء مباشر"
                  />
                </div>

                <div className="description-container">
                  <div className="description-text" dir="rtl">
                    الابعاد :
                  </div>

                  <div dir="rtl"> الطول : {product.length} سم</div>
                  <div dir="rtl"> العرض : {product.width} سم</div>
                  <div dir="rtl"> الارتفاع : {product.height} سم</div>
                  <div dir="rtl">ارتفاع القاعدة : {product.baseHeight} سم</div>
                  <div dir="rtl"> العمق : {product.depth} سم</div>

                  <div
                    className="description-text"
                    dir="rtl"
                    style={{ marginTop: "1rem" }}
                  >
                    الاقمشة المتوفرة :
                  </div>

                  <ul style={{ marginRight: "2rem" }}>
                    <li dir="rtl">كتان</li>
                    <li dir="rtl">قطيفة</li>
                  </ul>

                  <div
                    className="description-text"
                    dir="rtl"
                    style={{ marginTop: "1rem" }}
                  >
                    المواد الخام :
                  </div>

                  <ul style={{ marginRight: "2rem" }}>
                    <li dir="rtl">الخشب : زان احمر</li>
                    <li dir="rtl">اسفنج كثافة 30 سم</li>
                  </ul>
                </div>

                <div className="delivery-container">
                  <div
                    className="description-text"
                    dir="rtl"
                    style={{ marginTop: "1rem" }}
                  >
                    معلومات التسليم والشحن
                  </div>
                  <div dir="rtl">
                    طلب إضافي: تغطية رسوم الشحن حتى الطابق الرابع .. رسوم إضافية
                    بعد الدور الرابع 4 جنيه مصري للطابق
                  </div>
                  <div dir="rtl">التسليم: 10-15 يوم عمل</div>
                  <div dir="rtl">الموديل : {product.name}</div>
                  <div dir="rtl">
                    ملاحظة: نظرًا لأن المنتج مصمم خصيصًا لك ، فقد يستغرق الأمر
                    أكثر من الوقت المذكور
                  </div>
                  <div dir="rtl">الشحن: داخل القاهرة والجيزة فقط</div>
                </div>
              </div>
            ) : null}

            <div className="product-image right-column">
              {product ? (
                <Carousel responsive={responsive} infinite autoPlaySpeed={1800}>
                  {/* <img
                    className="productDetails_productImage"
                    src={`data:${product.image_type};base64,${product.image}`}
                    alt="FFF"


                    onClick={handleClick}
                  /> */}
                  <div onClick={handleClick}>
                    <ImageZoom
                      src={`data:${product.image_type};base64,${product.image}`}
                      zoomLevel={2}
                    />
                  </div>

                  <img className="color" src={KatefaColor} alt="FFF" />
                  <img className="color" src={KetanColor} alt="FFF" />
                </Carousel>
              ) : null}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
