import React from "react";
import "./footer.css";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import warshtyLogo2 from "../../assets/warshtyLogo2.png";
import CopyrightOutlinedIcon from "@mui/icons-material/CopyrightOutlined";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <div className="footer">
      <div className="footer_section">
        <div className="footer_section-column">
          <div
            className="help"
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_needHelp")}
          </div>
          <div
            className="footer_row"
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            }}
          >
            <LocalPhoneOutlinedIcon />
            <div className="phone-number">01117125323</div>
          </div>

          <div
            className="footer_row"
            style={{
              direction: `${i18n.language === "en" ? "ltr" : "rtl"}`,
            }}
          >
            <HomeOutlinedIcon />
            <div className="address">{t("footer_location")}</div>
          </div>
          <div></div>
        </div>
        <div className="footer_section-column">
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_aboutWarshty")}
          </div>
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_termsAndConditions")}
          </div>
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_privacy")}
          </div>
        </div>
        <div className="footer_section-column">
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_security")}
          </div>
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_goodsReturn")}
          </div>
          <div
            style={{
              textAlign: `${i18n.language === "en" ? "left" : "right"}`,
            }}
          >
            {t("footer_paymentMethods")}
          </div>
        </div>

        <div className="footer-logo_container">
          <img src={warshtyLogo2} className="footer-logo" />
        </div>
      </div>

      <div className="rights">
        جميع الحقوق محفوظة لـ ورشتى <CopyrightOutlinedIcon /> 2023
      </div>
    </div>
  );
};

export default Footer;
