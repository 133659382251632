import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/warshtyLogo.png";
import { AmiriFont } from "./font";
// import { Font } from "@react-pdf/renderer";
// import cairoRegular from '../fonts/Cairo-Bold.ttf'

// Font.register({ family: "Cairo", src: cairoRegular });

export async function savePDF(order) {

  const doc = new jsPDF();

  // Add font

  doc.addFileToVFS("Cairo-Regular.ttf", AmiriFont);
  doc.addFont("Cairo-Regular.ttf", "Cairo", "normal");
  doc.setFont("Cairo");

  // Add logo
  const imgData = logo;
  doc.addImage(imgData, "PNG", 10, 10, 100, 50);

  // Add header
  doc.setFontSize(20);
  doc.setR2L(false);
  doc.text("الفاتورة", 180, 70, null, null, "right");

  // Add invoice details
  doc.setFontSize(12);
  doc.text(`رقم الفاتورة : ${order.internal_code}`, 180, 80, {
    align: "right",
  });

  doc.text(`التاريخ: ${order.d.toLocaleDateString()}`, 180, 90, {
    align: "right",
  });

  doc.text(`اسم العميل: ${order.first_name} ${order.last_name}`, 180, 100, {
    align: "right",
  });

  doc.text(`العنوان : ${order.address}`, 180, 110, { align: "right" });

  const tablePosY = 120; // Initial Y position of the table
  const tableHeight = doc.autoTable.previous.finalY - tablePosY;
  // Add table
  doc.autoTable({
    startY: tablePosY,
    head: [["المنتج", "الكمية", "السعر"]],
    body: order.products.map((item) => [
      item.name,
      1,
      `$${item.price}`,
    ]),
    styles: {
      font: "Cairo",
      cell: { textAlign: "right", width: "auto" }, // Set width to 'auto'
      head: { textAlign: "right", width: "auto" }, // Set width to 'auto'
    },
  });

  const textPosY = tablePosY + tableHeight + 10;

  doc.text(
    `الاجمالى قبل الشحن: ${JSON.stringify(+order.total + +order.add)} جنيها`,
    180,
    textPosY,
    { align: "right" }
  );
  doc.text(`مصاريف الشحن: ${order.shipping} جنيها`, 180, textPosY + 10, {
    align: "right",
  });
  doc.text(`الاجمالى : ${order.finalTotal} جنيها`, 180, textPosY + 20, {
    align: "right",
  });
  doc.text(`الدفعة المقدمة : ${order.paid} جنيها`, 180, textPosY + 30, {
    align: "right",
  });
  doc.text(`المطلوب تحصيله : ${order.rest} جنيها`, 180, textPosY + 40, {
    align: "right",
  });
  doc.text(
    "أقر بأنى قمت بفحص المنتج وأنه خال من العيوب ومطابق للمواصفات",
    180,
    textPosY + 50,
    { align: "right" }
  );
  doc.text("تم دفع المبلغ ومراجعة المنتج", 180, textPosY + 60, {
    align: "right",
  });
  doc.text("توقيع العميل:", 180, textPosY + 70, { align: "right" });
  doc.text("توقيع المندوب:", 120, textPosY + 70, { align: "right" });

  const pdfBase64 = doc.output("datauristring").split(",")[1];
  return pdfBase64;
}
