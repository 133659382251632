import React from "react";
import "./primaryButton.css";

const PrimaryButton = ({ handleClick, text }) => {
  const className = text.includes("حذف") || text.includes("Delete") ? "red" : ""
  return (
    <div>
      <button className={`primaryButton ${className}`}  onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default PrimaryButton;
