import React from "react";
import "./reviews.css";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import ReviewCard from "../reviewCard/reviewCard";
import { reviews } from "../../utilities/reviews";

const Reviews = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="reviews_section">
      <h2 className="reviews_header">{t("reviews")}</h2>
      <div className="reviews_container">
        <Carousel
          responsive={responsive}
          autoPlay
          infinite
          autoPlaySpeed={1800}
        >
          {reviews.map((review) => (
            <ReviewCard reveiw={review.review} name={review.clientName} />
          ))}
          {/* <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard /> */}
        </Carousel>
      </div>
    </div>
  );
};

export default Reviews;
