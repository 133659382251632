import React from "react";
import "./advantages.css";
import advantage1 from '../../assets/advantage1.png'
import advantage2 from '../../assets/advantage2.png'
import advantage3 from '../../assets/advantage3.png'
import advantage4 from '../../assets/advantage4.png'

const Advantages = () => {
  return (
    <div className="advantages_section">
      <div className="advantage">
        <img  className="advantage_img" src={advantage1} alt="FFF"/>
      </div>
      <div className="advantage">
        <img className="advantage_img" src={advantage2} alt="FFF"/>
      </div>
      <div className="advantage">
        <img className="advantage_img" src={advantage3} alt="FFF"/>
      </div>
      <div className="advantage">
        <img className="advantage_img" src={advantage4} alt="FFF"/>
      </div>
    </div>
  );
};

export default Advantages;
