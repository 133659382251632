import "./App.css";
import HomePage from "./pages/homePage/homePage";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import Category from "./pages/category/category";
import ProductDetails from "./pages/productDetails/productDetails";
import CheckOut from "./pages/checkOut/checkOut";
import SucceededPage from "./pages/succeededPage/succeededPage";
import SearchPage from "./pages/search/search";
import SearchMobilePage from "./pages/searchMobile/searchMobile";
import PdfComponent from "./components/pdfInvoice/pdfInvoice";
import { useState } from "react";
import { UpdateImageContext } from "./context/updateImage";

function App() {
  const [updateImage, setUpdateImage] = useState(false);

  return (
    <UpdateImageContext.Provider value={{updateImage, setUpdateImage}}>
      <div>
        <HashRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/productDetails/:id" element={<ProductDetails />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/succeededPage" element={<SucceededPage />} />
            <Route path="/search/:category/:productName" element={<SearchPage />} />
            <Route path="/test" element={<PdfComponent />}/>
            <Route
              path="/searchMobile/:productName"
              element={<SearchMobilePage />}
            />
          </Routes>
        </HashRouter>
      </div>
    </UpdateImageContext.Provider>
  );
}

export default App;
