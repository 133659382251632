import React, { useContext } from "react";
import "./productCard.css";
import PrimaryButton from "../button/primaryButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { useTranslation } from "react-i18next";
import { UpdateImageContext } from "../../context/updateImage";

const ProductCard = ({
  name,
  price,
  image,
  image_type,
  id,
  addToCart,
  removeFromCart,
  isInCart,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);

  return (
    <div className="card">
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/productDetails/${id}`}
      >
        <div className="card_img_container">
          <img
            src={`data:${image_type};base64,${image}`}
            alt="FFF"
            className="card_img"
          />
        </div>

        <div className="card_productName">{name}</div>
      </Link>

      <div className="card_productPrice">
        {String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
      </div>

      <div className="card_productPrice_beforeDiscount">
        {String((+price * 10) / 100 + +price).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}{" "}
        EGP
      </div>
      {isInCart ? (
        <div className="button_container">
          <PrimaryButton
            text={t("card_delete_from_cart")}
            handleClick={() => removeFromCart(id)}
          />
        </div>
      ) : (
        <div className="button_container">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/productDetails/${id}`}
          >
            <PrimaryButton
              text={t("card_add_to_cart")}
              // handleClick={() => {
              //   addToCart({
              //     id,
              //     name,
              //     price,
              //     imageUrl: "",
              //     imageType: "",
              //     cornerDirection: "",
              //     matrialType: "",
              //     color: "",
              //   });
              //   setUpdateImage(!updateImage);
              // }}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
